<template>
  <div>
    <a
      rel="noreferrer"
      target="_blank"
      href="https://assoft.co"
      class="position-absolute  bottom-0 end-0 m-3"
      >Optima Health by Assoft</a
    >
    <form
      @submit.prevent="signup"
      class="p-3 position-absolute top-50 start-50 translate-middle border rounded public-form"
    >
      <h3 class="text-center">Registrarse</h3>
      <p class="text-center">Has parte de la familia Optima Health</p>
      <div class="mb-3 inputIcon">
        <label for="signupName" class="form-label">Nombre o Razón Social</label>
        <input
          autofocus
          type="text"
          class="form-control"
          id="signupName"
          aria-describedby="namrHelp"
          v-model="name"
          required
        />
        <svg class="bi" fill="currentColor">
          <use xlink:href="/img/icons/bootstrap-icons.svg#chat-square" />
        </svg>
      </div>
      <div class="mb-3 inputIcon">
        <label for="signupEmail" class="form-label">Correo Electrónico</label>
        <input
          autofocus
          type="email"
          class="form-control"
          id="signupEmail"
          aria-describedby="emailHelp"
          v-model="email"
          required
        />
        <svg class="bi" fill="currentColor">
          <use xlink:href="/img/icons/bootstrap-icons.svg#person" />
        </svg>
      </div>
      <div class="mb-3 inputIcon">
        <label for="signupPassword" class="col-6 form-label">Contraseña</label>
        <input
          type="password"
          class="form-control col-6"
          id="signupPassword"
          v-model="password"
          required
        />
        <svg class="bi" fill="currentColor">
          <use xlink:href="/img/icons/bootstrap-icons.svg#lock" />
        </svg>
      </div>
      <div class="mb-3 inputIcon">
        <label for="signupPasswordConfirm" class="col-6 form-label"
          >Confirmar Contraseña</label
        >
        <input
          type="password"
          class="form-control col-6"
          id="signupPasswordConfirm"
          v-model="passwordConfirm"
          required
        />
        <svg class="bi" fill="currentColor">
          <use xlink:href="/img/icons/bootstrap-icons.svg#lock" />
        </svg>
      </div>
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="checkTerms"
          v-model="checkTerms"
          required
        />
        <label class="form-check-label" for="checkTerms"
          >Acepto los
          <router-link target="_blank" to="/terms"
            >términos, condiciones</router-link
          >
          y
          <router-link target="_blank" to="/politics"
            >políticas de privacidad</router-link
          ></label
        >
      </div>
      <div class="d-grid gap-2">
        <button type="submit" class="btn btn-primary shadow-primary">
          Registrarse
        </button>
      </div>
      <p class="mt-3 text-center">
        ¿Ya es Usuario? <router-link to="/login">Inicie Sesión</router-link>
      </p>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Session from "@/modules/session";
import { validatePassword } from "@/modules/tools";

export default {
  data() {
    return {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      checkTerms: false
    };
  },
  name: "Login",
  methods: {
    signup() {
      Swal.fire({
        icon: "warning",
        title: "Procesando",
        text:
          "Estamos procesando toda la información necesaria para crear la cuenta",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        }
      });
      const validate = validatePassword(this.passwordConfirm);

      if (!this.checkTerms) {
        Swal.fire({
          title: "Términos y Condiciones",
          icon: "warning",
          text:
            "Por favor acepte los términos y condiciones para realizar el registro."
        });
        return 0;
      } else if (this.password !== this.passwordConfirm) {
        Swal.fire({
          title: "Las contraseñas no coinciden",
          icon: "warning",
          text: "Por favor verifique que las contraseñas ingresadas coincidan."
        });
        return 0;
      } else if (!validate[0]) {
        Swal.fire({
          title: "Las contraseña es incorrecta",
          icon: "warning",
          html:
            "Por favor verifique que la contraseña cumple con los siguientes criterios</br></br>" +
            "<ul>" +
            '<li style="text-align: left;">Más de 8 ccaracteres.</li>' +
            '<li style="text-align: left;">Menos de 50 ccaracteres.</li>' +
            '<li style="text-align: left;">Al menos una letra en mayúsculas.</li>' +
            '<li style="text-align: left;">Al menos una letra en minúscula.</li>' +
            '<li style="text-align: left;">Al menos un dígito.</li>' +
            '<li style="text-align: left;">Al menos un caracter especial: <strong>ñÑ~!@#$%^&*+-/.,{}[]();: </strong></li>' +
            "</ul>"
        });
        return 0;
      } else {
        const result = new Session()
          .signUpEmail(this.name, this.email, this.passwordConfirm)
          .then(user => {
            this.$router.push({ name: "Welcome" });
          })
          .catch(error => {
            if (error.response.data.body.message == "User already exists") {
              Swal.fire({
                title: "El Usuario ya Existe",
                icon: "warning",
                text:
                  "El correo electrónico proporcionado ya existe en nuestra base de datos, por favor ingrese otro email. Si ha olvidado la contraseña, por favor de click en Inicie Sesión y después en ¿Olvidó su Contraseña?."
              });
            }
          });
      }
    }
  }
};
</script>
